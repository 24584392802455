.station-name {
    min-height: 100vh;
    vertical-align: middle;
    font-size: 2.5rem;
    text-align: center;
}

.transfer-container {
    min-height: 100vh;
    padding: 1rem;
}

.transfer-line-name {
    font-size: 1.2rem;
}

.transfer-station-container {
    padding-left: 1rem;
}