.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  width: 80%;
  height: 80%;
  margin: auto;
  position: absolute;
  inset: 0;
  border: none !important;
  background: rgba(255, 255, 255, 0.6) !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 1rem !important;
}
