a {
  text-decoration: none;
}

.personalityContent {
  padding: 2rem;
}

.personalityIntro {
  margin: 12rem auto 20rem auto;

  p {
    text-align: center;
    margin: 0 auto 3rem 0;
    font-family: 'Shippori Mincho', serif;
  }
}

#sasameTableHead {
  width: fit-content;
  color: #7e8fd7;
  text-align: center;
  padding: 0.25em 1rem;
  margin: 3rem auto;
  font-weight: bold;
  border-top: solid 2px #7e8ed7bb;
  border-bottom: solid 2px #7e8ed7bb;
  /* background: -webkit-repeating-linear-gradient(-45deg, #f0f8ff, #f0f8ff 3px,#e9f4ff 3px, #e9f4ff 7px);
    background: repeating-linear-gradient(-45deg, #f0f8ff, #f0f8ff 3px,#e9f4ff 3px, #e9f4ff 7px); */
}

.sasameTableBottom {
  font-size: 0.8rem;
  margin: 4rem 0 12rem 0;
  text-align: right;
}

@media screen and (max-width: 864px) {
  .personalityContent {
    padding: 1rem;
  }

  .personalityIntro {
    margin: 12rem auto 20rem auto;
  }
}

.howtotakepicturesContent {
  padding: 4rem 2rem;
  max-width: 864px;
  width: 100%;
  margin: 0 auto;
  /* text-align: center; */
}

.howtotakepicturesIntro {
  p {
    text-align: center;
    margin: 0 auto 1rem 0;
  }
}

.threeTipsHeader {
  font-weight: bold;
  text-align: center;
  margin-top: 3rem;
  font-size: 1.2rem;
}

.threeTips {
  border: 2px dotted #fec488;
  border-radius: 1rem;
  padding: 1rem 2rem;
  margin: 1rem 2rem;
  font-weight: bold;

  span {
    color: #71bbd0;
  }

  li {
    margin: 1rem 0 1rem 1rem;
    list-style: decimal;
  }
}

.howtotakepicturesIntro2 {
  p {
    text-align: center;
    margin: 3rem 0;
  }
}

.howtotakepictures-content h3 {
  position: relative;
  padding-left: 1.5em;
  line-height: 1.4;
  color: black;
  margin: 3rem auto 1rem auto;
}

.howtotakepictures-content h3:before {
  content: '●';
  font-weight: 900;
  position: absolute;
  font-size: 1.4em;
  left: 0;
  top: -0.2em;
  color: #bac4ee;
}

.pointTips {
  p {
    margin: 1rem;
  }
  span {
    color: #ff8100;
    font-weight: bold;
  }
}

.pointSituation {
  span {
    color: #ff8100;
    font-weight: bold;
  }
}

.pointImgContainer {
  width: 100%;
  margin: 1rem;
}

.pointImgContent {
  width: auto;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.pointImgInner {
  border: 2px solid #ffdb87;
  padding: 1.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;

  img {
    display: block;
    width: 192px;
  }
}

.pointImgBad,
.pointImgGood {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  transform: translate(-50%, -50%);
}

.pointImgBad::before,
.pointImgBad::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 2rem;
  background: #3c51a5;
}

.pointImgBad::before {
  transform: translate(-2px, -50%) rotate(45deg);
}

.pointImgBad::after {
  transform: translate(-2px, -50%) rotate(-45deg);
}

.pointImgGood::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background: none;
  border: 4px solid #f35b43;
  transform: translate(-50%, -50%);
}

.horizontalImg {
  width: auto !important;
  height: 192px;
}

.point-situation h4 {
  font-weight: 700;
  font-size: 1.2rem;
  background: linear-gradient(transparent 70%, #fedb88 70%);
  padding: 0 1rem 0 0;
  margin: 1rem 1rem;
  width: fit-content;
}

.point-situation p {
  margin: 1rem;
}

.situationImgContainer {
  margin-bottom: 3rem;
}

@media screen and (max-width: 864px) {
  .howtotakepicturesContent {
    padding: 3rem 1rem;
  }

  .threeTips {
    margin: 1rem 0;
  }

  .pointImgContainer {
    width: auto;
    height: auto;
  }

  .pointImgContent {
    flex-direction: column;
  }

  .pointImgInner {
    flex-direction: column;

    img {
      display: block;
      width: 100% !important;
      max-width: 256px;
    }
  }

  .horizontalImg {
    height: auto !important;
  }
}

.tripContent {
  max-width: 100%;
  color: #fff;
  font-family: 'Shippori Mincho', serif;
}

.tripContentInner {
  padding: 0 4rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.tripContent:nth-child(2n + 1) {
  .tripContentInner {
    flex-direction: row-reverse;
  }
}

.pink {
  background-color: #ed7daa;
}

.red {
  background-color: #ff9787;
}

.orange {
  background-color: #ffb487;
}

.yellow {
  background-color: #ffe187;
}

.green {
  background-color: #75df95;
}

.lightgreen {
  background-color: #b3e77a;
}

.blue {
  background-color: #7e8fd7;
}

.lightblue {
  background-color: #71bbd0;
}

.purple {
  background-color: #be74d5;
}

.tripHeader {
  margin-bottom: 3rem;
}

.tripHeaderBottom {
  padding: 1rem;
  letter-spacing: 0.3rem;
  white-space: nowrap;
}

.tripHeaderTop {
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  // text-orientation: upright;
}

.tripHeaderTextMain {
  font-size: 2.5rem;
  letter-spacing: 1rem;
  padding: 4rem 0 4rem 0.5rem;
  border-left: 1px solid #fff;
}

.tripHeaderTextSub {
  font-size: 1rem;
  letter-spacing: 0.5rem;
  padding: 4.5rem 0.5rem;
  color: #fff;
}

.tripMain {
  padding: 4rem 3rem;
  min-width: 0;
}

.tripMainImgContainer {
  width: 100%;
}

@media screen and (max-width: 864px) {
  .tripContentInner {
    display: block;
    padding: 0;
  }

  .tripHeader {
    margin: 0 auto;
  }

  .tripHeaderTop {
    writing-mode: horizontal-tb;
  }

  .tripHeaderTextMain {
    margin-right: 3rem;
    border-left: none;
    border-bottom: 1px solid #fff;
    padding: 1rem 1rem 0.5rem 2rem;
    font-size: 2rem;
  }

  .tripHeaderTextSub {
    padding: 0.5rem 2rem;
  }

  .tripHeaderBottom {
    padding: 1rem 2rem;
  }

  .tripMain {
    padding: 1rem;
  }
}

.slider {
  width: 100%;
  // aspect-ratio: 5/3;
  margin-bottom: 3rem;
  max-width: 1024px;
  -webkit-mask-image: linear-gradient(
    90deg,
    rgb(255, 255, 255, 0) 0%,
    rgba(255, 255, 255) 5%,
    rgba(255, 255, 255) 95%,
    rgba(255, 255, 255, 0) 100%
  );
  mask-image: linear-gradient(
    90deg,
    rgb(255, 255, 255, 0) 0%,
    rgba(255, 255, 255) 5%,
    rgba(255, 255, 255) 95%,
    rgba(255, 255, 255, 0) 100%
  );

  img {
    display: block;
    height: 100%;
  }
}

.hoverImgContainer {
  overflow: hidden;

  a {
    display: block;
    transition: all 0.3s;
    overflow: hidden;
  }
  img {
    transform: scale(1);
    transition: all 0.3s;
  }
}

.hoverImgContainer:hover {
  a {
    transform: scale(0.96);
  }
  img {
    transform: scale(1.1);
  }
}
